import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  headerContainer: {
    backgroundColor: 'black',
    justifyContent: 'flex-end',
    paddingVertical: 10
  },
  pageTitle: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16
  },
  centerTextContainer: {
    alignItems: 'center'
  },
  subheaderContainer: {
    backgroundColor: '#646464',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 3,
    paddingHorizontal: 8
  },
  subheaderTitle: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 12
  },
  subheaderSubtitle: {
    color: 'white',
    fontSize: 12
  },
  openAppButton: {
    backgroundColor: '#FF005C',
    justifyContent: 'center',
    paddingHorizontal: 15,
    paddingVertical: 9,
    borderRadius: 3
  },
  buttonText: {
    color: 'white',
    fontSize: 12
  }
})