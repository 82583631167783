import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Pressable, Text, View } from 'react-native';
import { useParams } from 'react-router-dom';
import { GetBoardContext } from '../context/boardContext';
import { getBoard } from '../graphql/queries';
import { openApp } from '../helpers/openApp';
import LookDisplay from './LookDisplay';
import styles from './Main.styles';

const Main = () => {
  const { setCurrentOutfit } = GetBoardContext()
  const [tops, setTops] = useState([])
  const [bottoms, setBottoms] = useState([])
  const [shoes, setShoes] = useState([])
  const [name, setName] = useState('')
  const [title, setTitle] = useState('')
  const { id } = useParams()

  useEffect(() => {
    const getData = async () => {

      const board = await API.graphql({
        query: getBoard,
        variables: { id: id }
      })
      if (board.data) {
        const topsData = extractPieces(board.data.getBoard.pieces.items, 'TOP')
        const bottomsData = extractPieces(board.data.getBoard.pieces.items, 'BOTTOM')
        const shoesData = extractPieces(board.data.getBoard.pieces.items, 'SHOES')
        const name = board.data.getBoard.cognitoAttributes.filter(userAttribute => userAttribute.Name === 'name')[0].Value
        const caption = board.data.getBoard.caption

        if (caption !== null && caption !== '') {
          setTitle(caption)
        } else {
          setTitle(`${name}'s Board`)
        }

        setTops(topsData)
        setBottoms(bottomsData)
        setShoes(shoesData)
        setName(name)

        setCurrentOutfit({
          top: topsData.length ? topsData[0] : null,
          bottom: bottomsData.length ? bottomsData[0] : null,
          shoes: shoesData.length ? shoesData[0] : null
        })
      }
    }
    getData()
  }, [])

  const extractPieces = (data, type) => {
    return data
      .filter(p => p.piece.type === type)
      .map(p => p.piece)
  }

  return (
    <View
      style={{height: '100%', width: '100%'}}>
      <View style={[styles.headerContainer, styles.centerTextContainer]}>
        { title !== '' && (
          <Text style={styles.pageTitle}>{title}</Text>
        )}
      </View>
      <View style={[styles.subheaderContainer]}>
        <View>
          { name !== '' && (
            <Text style={styles.subheaderTitle}>{name}</Text>
          )}
          <Text style={styles.subheaderSubtitle}>Join Me on The Look!</Text>
        </View>
        <Pressable
          onPress={openApp}
          style={styles.openAppButton}
        >
          <Text style={styles.buttonText}>Open App</Text>
        </Pressable>
      </View>
      <LookDisplay
        tops={tops}
        bottoms={bottoms}
        shoes={shoes}
      />
    </View>
  )
}

export default Main