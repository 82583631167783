import React from 'react'
import { ScrollView, Text, View } from 'react-native'
import { OUTFIT_IMAGE_MARGIN, OUTFIT_IMAGE_WIDTH, SCREEN_WIDTH } from '../helpers/constants'
import Piece from './Piece'
import styles from './PiecesRow.styles'

const PiecesRow = ({ pieces, setCurrentPieceIndex }) => {
  const padding = (SCREEN_WIDTH - OUTFIT_IMAGE_WIDTH) / 2
  const scrollDistance = OUTFIT_IMAGE_WIDTH + OUTFIT_IMAGE_MARGIN

  const scrollAction = (xOffset) => {
    const index = Math.round(xOffset / scrollDistance)
    console.log(index)
    setCurrentPieceIndex(index)
  }

  const MiddleIndicator = () => {
    return (
      <View style={styles.indicator} />
    )
  }

  return (
    <>
    <MiddleIndicator/>
    <View style={styles.rowContainer}>
      <ScrollView
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        onScroll={({nativeEvent}) => {
          scrollAction(nativeEvent.contentOffset.x)
        }}
        keyExtractor={(item) => item.id}
      >
        <View style={{width: padding}}/>
        {pieces.map(item => 
          <Piece key={item.id} item={item}/>
        )}
        <View style={{width: padding}}/>
      </ScrollView>
    </View>
    </>
  )
}

export default PiecesRow