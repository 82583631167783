import React, { useEffect, useState } from 'react'
import { ScrollView, Text, View } from 'react-native'
import styles from './LookDisplay.styles'
import { GetBoardContext } from '../context/boardContext'
import PiecesRow from './PiecesRow'

const LookDisplay = ({ tops, bottoms, shoes }) => {
  const { currentOutfit, setCurrentOutfit } = GetBoardContext()
  const [currentTopIndex, setCurrentTopIndex] = useState(0)
  const [currentBottomIndex, setCurrentBottomIndex] = useState(0)
  const [currentShoesIndex, setCurrentShoesIndex] = useState(0)

  useEffect(() => {
    setCurrentOutfit({
      top: tops.length ? tops[currentTopIndex] : null,
      bottom: bottoms.length ? bottoms[currentBottomIndex] : null,
      shoes: shoes.length ? shoes[currentShoesIndex] : null,
    })
  }, [currentTopIndex, currentBottomIndex, currentShoesIndex])

  return (
    <ScrollView
      style={[styles.container, {zIndex: 2}]}
      scrollEnabled={true}
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={{paddingBottom: 200 }}
    >
      <View style={[styles.centerTextContainer]}>
        <Text
          onPress={() => {console.log(currentOutfit)}}
          style={styles.whichOutfitText}>
            Which Outfit...
        </Text>
      </View>
      { tops.length > 0 && (
        <PiecesRow
          pieces={tops}
          setCurrentPieceIndex={setCurrentTopIndex}
        />
      )}
      { bottoms.length > 0 && (
        <PiecesRow
          pieces={bottoms}
          setCurrentPieceIndex={setCurrentBottomIndex}
        />
      )}
      { shoes.length > 0 && (
        <PiecesRow
          pieces={shoes}
          setCurrentPieceIndex={setCurrentShoesIndex}
        />
      )}
    </ScrollView>
  )
}

export default LookDisplay