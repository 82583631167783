import React, { useState } from 'react'
import { ImageBackground, Text, Pressable, View, TouchableOpacity, Linking } from 'react-native'
import { OUTFIT_IMAGE_HEIGHT, OUTFIT_IMAGE_MARGIN, OUTFIT_IMAGE_WIDTH } from '../helpers/constants'
import { AiOutlineClose } from 'react-icons/ai'
import { BsLink45Deg } from 'react-icons/bs'
import styles from './Piece.styles'

const Piece = ({ item }) => {
  const {
    imageUrls: options, 
    price,
    brand,
    url
  } = item
  const numOptions = options.length
  const [displayIndex, setDisplayIndex] = useState(1)
  
  const renderPieceInfo = () => (
    <Pressable
      onPress={() => setDisplayIndex(i => (i + 1) % numOptions)}
      style={styles.infoWrap}
    >
      <View />
      <View style={styles.infoContainer}>
        <View style={styles.infoLeft}>
          <View style={styles.brand}>
            { brand && <Text style={styles.brandTitle} numberOfLines={1}>{brand}</Text> }
            { price && <Text style={styles.brandSubTitle}>{`$${price.toFixed(2)}`}</Text> }
          </View>
          <TouchableOpacity
            activeOpacity={0.7}
            onPress={() => {
              if (url) {
                Linking.openURL(url)
              } else {
                Linking.openURL('https://www.zara.com/')
              }
            }}
            style={styles.infoLink}>
            <BsLink45Deg name="link" size={24} color="#007AFF" />
          </TouchableOpacity>
        </View>
      </View>
    </Pressable>
  )

  return (
    <View>
      <ImageBackground 
        source={{uri: options[displayIndex]}} 
        resizeMode="cover" style={[styles.image, {
          width: OUTFIT_IMAGE_WIDTH,
          height: OUTFIT_IMAGE_HEIGHT,
          marginRight: OUTFIT_IMAGE_MARGIN
        }]}>
        {renderPieceInfo()}
      </ImageBackground>
    </View>
  )
}

export default Piece