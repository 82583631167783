import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  screen: {
    height: '100%',
    width: '100%',
    maxHeight: 896,
    maxWidth: 414,
    aspectRatio: 0.46,
    backgroundColor: 'white',
    position: 'relative',
    borderRadius: 8,
    overflow: 'hidden'
  },
  buttonContainer: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionContainer: {
    fontStyle: 'normal',
    fontWeight: 600,
    width: '66%',
    fontSize: 16,
    color: 'white',
    border: 0,
    backgroundColor: 'black',
    userSelect: 'none',
    opacity: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 15,
    alignItems: 'center',
    paddingVertical: 10,
    marginTop: 10,
    marginBottom: 20,
  },
  withCursor: {
    cursor: 'pointer',
    justifyContent: 'center'
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
    marginLeft: 10,
    justifyContent: 'center'
  },
  textInput: {
    backgroundColor: 'white',
    paddingHorizontal: 5,
    paddingVertical: 3,
    width: '85%',
    border: 0,
    outlineStyle: 'none'
  },
  arrowContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 22,
    width: 22,
    borderRadius: 15,
    backgroundColor: '#fff'
  }
})