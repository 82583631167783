import React from "react";
import { View, Text, Image, Pressable, FlatList } from "react-native";
import { BsChevronDown } from 'react-icons/bs'
import { openApp } from "../helpers/openApp";
import styles from "./CongratulationsScreen.styles";
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '../helpers/constants'

const images = [
  require('../assets/store/appstore-1.png'),
  require('../assets/store/appstore-2.png'),
  require('../assets/store/appstore-3.png'),
  require('../assets/store/appstore-4.png'),
  require('../assets/store/appstore-5.png'),
  require('../assets/store/appstore-6.png'),
  require('../assets/store/appstore-7.png')
]

const CongratulationsScreen = ({ name }) => {
  const renderCongratulations = () => (
    <View style={{height: SCREEN_HEIGHT, width: SCREEN_WIDTH}}>
      <View style={styles.bodyContainer}>
        <View style={styles.subTextContainer}>
          <Text style={styles.subText}>Outfit sent</Text>
          <Text style={styles.subText}>to</Text>
          <Text style={styles.subText}>{name}!</Text>
        </View>
        <Pressable onPress={openApp}>
          <Image
            source={require("../assets/app-store.png")}
            style={styles.appStore}
          />
        </Pressable>
        <View style={styles.scrollBottom}>
          <Text style={[styles.subText, {color: 'gray'}]}>Scroll for more</Text>
          <BsChevronDown size={23} color={'gray'} />
        </View>
      </View>
    </View>
  );

  const renderItem = ({ item }) => (
    <Image resizeMode="contain" style={{height: SCREEN_HEIGHT, width: '100%'}} source={item} />
  );

  return (
    <View style={{ width: "100%", height: "100%" }}>
      <View style={styles.headerContainer}>
        <Text style={styles.pageTitle}>THE LOOK</Text>
      </View>
       <FlatList
        data={images}
        renderItem={renderItem}
        ListHeaderComponent={renderCongratulations}
        keyExtractor={item => item.id}
      />
    </View>
  );
};

export default CongratulationsScreen;
