export const getOutfit = /* GraphQL */ `
  query GetOutfit($id: ID!) {
    getOutfit(id: $id) {
      id
      owner
      pieces {
        items {
          id
          pieceID
          piece {
            id
            imageUrls
            type
            price
            url
            brand
          }
          outfitID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const getBoard = /* GraphQL */ `
  query GetBoard($id: ID!) {
    getBoard(id: $id) {
      id
      owner
      caption
      cognitoAttributes {
        Name
        Value
      }
      pieces {
        items {
          id
          piece {
            id
            imageUrls
            type
            price
            url
            brand
          }
          boardID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      outfit {
        items {
          id
          piece {
            id
            imageUrls
            type
            price
            url
            brand
          }
          boardID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          owner
        }
        nextToken
        startedAt
      }
      boardSuggestions {
        items {
          id
          name
          boardID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;