export const createBoardSuggestion = /* GraphQL */ `
  mutation CreateBoardSuggestion(
    $input: CreateBoardSuggestionInput!
    $condition: ModelBoardSuggestionConditionInput
  ) {
    createBoardSuggestion(input: $input, condition: $condition) {
      id
      name
      outfit {
        items {
          id
          pieceID
          boardSuggestionID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      boardID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createBoardSuggestionOutfit = /* GraphQL */ `
  mutation CreateBoardSuggestionOutfit(
    $input: CreateBoardSuggestionOutfitInput!
    $condition: ModelBoardSuggestionOutfitConditionInput
  ) {
    createBoardSuggestionOutfit(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;