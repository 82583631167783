import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GlobalState from './context/GlobalState';
import Screen from './screens/Screen'
import LandingPage from './screens/LandingPage';
import PrivacyPolicy from './screens/PrivacyPolicy';
import TermsOfService from './screens/TermsOfService';
import SupportScreen from './screens/SupportScreen';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />}/>
          <Route path="/terms_of_service" element={<TermsOfService />}/>
          <Route path="/privacy_policy" element={<PrivacyPolicy />}/>
          <Route path="/support" element={<SupportScreen />}/>
          <Route path="/:id" element={<Screen />}/>
        </Routes>
      </Router>
    </div>
  );
}

const AppWithContext = () => {
  return (
    <GlobalState>
      <App />
    </GlobalState>
  )
}

export default AppWithContext
