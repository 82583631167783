import { API } from 'aws-amplify'
import React, { useState } from 'react'
import { AiOutlineArrowUp } from 'react-icons/ai'
import { Pressable, Text, TextInput, TouchableOpacity, View } from 'react-native'
import { useParams } from 'react-router-dom'
import CongratulationsScreen from './CongratulationsScreen'
import { GetBoardContext } from '../context/boardContext'
import { createBoardSuggestion, createBoardSuggestionOutfit } from '../graphql/mutations'
import Main from '../components/Main'
import styles from './Screen.styles'

const Screen = () => {
  const { currentOutfit } = GetBoardContext()
  const [submitPressed, setSubmitPressed] = useState(false)
  const [doneSubmitting, setDoneSubmitting] = useState(false)
  const [name, setName] = useState("")
  const { id } = useParams()

  const addPieceToSuggestion = async (pieceID, suggestionID) => {
    const boardSuggestionOutfitDetails = {
      pieceID: pieceID,
      boardSuggestionID: suggestionID
    }
    await API.graphql({
      query: createBoardSuggestionOutfit,
      variables: { input: boardSuggestionOutfitDetails }
    })
  }

  const submitSuggestion = async () => {
    if (name !== "" && Object.keys(currentOutfit).length) {
      const boardSuggestionDetails = {
        name: name,
        boardID: id
      }
      console.log(boardSuggestionDetails)
      const boardSuggestion = await API.graphql({
        query: createBoardSuggestion,
        variables: { input: boardSuggestionDetails }
      })
      if (boardSuggestion.data) {
        const boardSuggestionID = boardSuggestion.data.createBoardSuggestion.id
        Object.values(currentOutfit)
        .filter(value => value)
        .map(value => {
          addPieceToSuggestion(value.id, boardSuggestionID)
        })
        setDoneSubmitting(true)
      }
    }
  }

  if (doneSubmitting) {
    return (
      <View style={styles.screen}>
        <CongratulationsScreen name={name}/>
      </View>
    )
  }

  return (
    <View style={styles.screen}>
      <Main/>
      <View style={styles.buttonContainer}>
        {submitPressed ? (
          <View style={styles.actionContainer}>
            <TextInput
              style={styles.textInput}
              autoFocus={true}
              placeholder={"Enter Your Name"}
              value={name}
              onChangeText={setName}
              onSubmitEditing={submitSuggestion}
            />
            <TouchableOpacity
              onPress={submitSuggestion}
              style={[styles.arrowContainer, {backgroundColor: !!name ? '#007aff': '#fff'}]}
            >
              <AiOutlineArrowUp color={!!name ? '#fff' : '#000'} size={18} />
            </TouchableOpacity>
          </View>
        ) : (
        <Pressable
          onPress={() => setSubmitPressed(true)}
          style={[styles.actionContainer, styles.withCursor]}>
          <Text style={styles.buttonText}>SUBMIT</Text>
        </Pressable>
        )}
      </View>
    </View>
  )
}

export default Screen;