import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  headerContainer: {
    backgroundColor: 'black',
    justifyContent: 'flex-end',
    height: '10%',
    paddingLeft: 10,
    paddingBottom: 15
  },
  bodyContainer: {
    height: '90%',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingVertical: 40,
  },
  appStore: {
    height: 78,
    width: 200,
    marginBottom: 40
  },
  mainText: {
    fontWeight: 'bold',
    fontSize: 20,
    marginBottom: 20
  },
  subTextContainer: {
    textAlign: 'center'
  },
  subText: {
    fontSize: 18,
    fontWeight: '600',
    letterSpacing: 0.5,
    marginTop: 2
  },
  pageTitle: {
    color: 'white',
    fontSize: 20
  },
  scrollBottom: {
    alignItems: 'center'
  }
})