import React, { useState } from 'react';
import { BoardContext } from './boardContext';

const GlobalState = ({ children }) => {
  const [currentOutfit, setCurrentOutfit] = useState({})

  const value = { currentOutfit, setCurrentOutfit }
  return (
    <BoardContext.Provider value={value}>
      {children}
    </BoardContext.Provider>
  )
}

export default GlobalState