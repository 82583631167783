/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://gxnfmo56r5cexmpsm4lhcm3k4y.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-njuovuglpvgfvjvlaut7c352fm",
    "aws_cognito_identity_pool_id": "us-east-1:ab89b4c0-40f8-4a0e-8b50-0a99c579a4c5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Kr0lnXQxW",
    "aws_user_pools_web_client_id": "5n5gsqg6fautjin5h4t2lat356",
    "oauth": {
        "domain": "thelook49159503-49159503-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:19000/",
        "redirectSignOut": "http://localhost:19000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "thelook-storage-d159r6v8rc65pn90711-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
