import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  image: {
    alignSelf: 'center',
  },
  deleteBtn: {
    flexDirection: 'row-reverse', 
    padding: 7,
  },
  infoWrap: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1
  },
  infoContainer: {
    flexDirection: 'row',
    padding: 7,
    justifyContent: 'space-between'
  },
  infoLeft: {
    flexDirection: 'column',
  },
  infoRight: {
    alignSelf: 'flex-end',
  },
  brand: {
    backgroundColor: '#fff',
    marginBottom: 6,
    paddingHorizontal: 5,
    maxWidth: 120,
    justifyContent: 'center',
  },
  brandTitle: {
    fontSize: 10,
    fontWeight: '500'
  },
  brandSubTitle: {
    fontSize: 11,
    fontWeight: 'bold'
  },
  infoLink: {
    backgroundColor: '#fff',
    height: 26,
    width: 40,
    justifyContent: 'center',
    alignItems: 'center'
  }
})