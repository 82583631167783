import React from 'react'
import { Linking, Text, View } from 'react-native'
import styles from './LandingPage.styles'

const LandingPage = () => {

  const clickTOS = async () => {
    const url = await Linking.getInitialURL()
    Linking.canOpenURL(`${url}terms_of_service`).then((supported) => {
      return Linking.openURL(`${url}terms_of_service`);
    });
  }

  const clickPrivacy = async () => {
    const url = await Linking.getInitialURL()
    Linking.canOpenURL(`${url}privacy_policy`).then((supported) => {
      return Linking.openURL(`${url}privacy_policy`);
    });
  }

  return (
    <View style={styles.container}>
      <View style={styles.headerContiner}>
        <Text style={styles.headerText}>THE</Text>
        <Text style={styles.headerText}>LOOK</Text>
      </View>
      <View style={styles.subheaderContainer}>
        <Text style={styles.subheaderText}>The Interactive Clothing Store</Text>
      </View>
      <View style={styles.footerContainer}>
        <Text onPress={clickTOS} style={styles.footerText}>Terms of Service</Text>
        <Text onPress={clickPrivacy} style={styles.footerText}>Privacy Policy</Text>
      </View>
    </View>
  )
}

export default LandingPage;