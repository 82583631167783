import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: 'black'
  },
  headerContiner: {
    width: '100%',
    height: '70%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  subheaderContainer: {
    width: '100%',
    height: '20%',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  footerContainer: {
    height: '10%',
    marginHorizontal: '20%',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  footerText: {
    color: 'white',
    textDecorationLine: 'underline'
  },
  headerText: {
    color: 'white',
    fontSize: '20vmin',
    fontStyle: 'bold',
    marginBottom: 10
  },
  subheaderText: {
    color: 'white',
    fontSize: '5vmin',
    marginBottom: 10
  },
})