import { createContext, useContext } from 'react';

const BoardContext = createContext({
  currentOutfit: {},
  setCurrentOutfit : (outfit) => {}
})

const GetBoardContext = () => {
  const context = useContext(BoardContext)
  if (context === undefined) {
    throw new Error('Unable to set up user context')
  }
  return context
}

export { BoardContext, GetBoardContext };
