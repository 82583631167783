import { StyleSheet } from 'react-native'
import { OUTFIT_IMAGE_HEIGHT } from '../helpers/constants'

export default StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
  },
  carousel: {
    paddingLeft: 20,
  },
  centerTextContainer: {
    alignItems: 'center'
  },
  whichOutfitText: {
    fontWeight: 'bold',
    paddingVertical: 5
  },
  button: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    height: '75%',
    backgroundColor: '#acadac',

    zIndex: 1
  },
  rightButton: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    height: '75%',
    backgroundColor: '#acadac',
    right: 0,
    zIndex: 1
  },
  rowContainer: {
    width: '100%',
    height: OUTFIT_IMAGE_HEIGHT,
    flexDirection: 'row',
    alignItems: 'center'
  },
  indicator: {
    marginTop: 2,
    height: 8,
    width: 2,
    backgroundColor: 'red',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  }
})