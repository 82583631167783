import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: 'black'
  },
  mainContainer: {
    width: '100%',
    height: '70%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  },
  subheaderContainer: {
    width: '100%',
    height: '20%',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  footerContainer: {
    height: '10%',
    marginHorizontal: '20%',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  footerText: {
    color: 'white',
    textDecorationLine: 'underline'
  },
  mainText: {
    color: 'white',
    fontSize: '3vmin',
  },
  subheaderText: {
    color: 'white',
    fontSize: '5vh',
    marginBottom: 10
  },
})