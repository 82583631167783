import React from 'react'
import { Linking, Text, View } from 'react-native'
import styles from './SupportScreen.styles'

const SupportScreen = () => {
  const clickEmail = async () => {
    window.open('mailto:contact@jointhelook.com?subject=The%20Look%20Support')
  }

  const clickTOS = async () => {
    const url = await Linking.getInitialURL()
    Linking.canOpenURL(`${url}terms_of_service`).then((supported) => {
      return Linking.openURL(`${url}terms_of_service`);
    });
  }

  const clickPrivacy = async () => {
    const url = await Linking.getInitialURL()
    Linking.canOpenURL(`${url}privacy_policy`).then((supported) => {
      return Linking.openURL(`${url}privacy_policy`);
    });
  }

  return (
    <View style={styles.container}>
      <View style={styles.mainContainer}>
        <Text style={styles.mainText}>For all support, please email </Text>
        <Text
          style={[styles.mainText, {textDecorationLine: 'underline'}]}
          onPress={clickEmail}
        >
          contact@jointhelook.com
        </Text>
      </View>
      <View style={styles.subheaderContainer}>
      </View>
      <View style={styles.footerContainer}>
        <Text onPress={clickTOS} style={styles.footerText}>Terms of Service</Text>
        <Text onPress={clickPrivacy} style={styles.footerText}>Privacy Policy</Text>
      </View>
    </View>
  )
}

export default SupportScreen;